import React ,{useState }from "react";
import moment from "moment";
import { Outlet } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import SocialIcons from "../component/Footer/SocialIcon";
import ItemsContainer from "../component/Footer/FooterItems";
import { Icons } from "../Utils/Menu";
import "../component/Footer/footer.css";
import "../component/Nav/navbar.css";
import '../component/Carrosel/CarouselComponent.css';
import { navItems } from "../Utils/Menu";
import { RxDropdownMenu } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
 

const DesignLayout = () => {
  const today = moment().format("YYYY");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [openNav, setOpenNav] = useState(false);
  const navigate = useNavigate();

   
  const handleNavClick = (href) => {
    if (href === "/our-work") {
      navigate("/sectors/research");
    } else {
      navigate(href);
    }
    // setOpenNav(false);
  };
  

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <div className="container_main">
            <div>
              <img src="/rawa_logos.png" alt="updated logo" className="updated_logo" />
            </div>
            <div className="navHeader">
          
              {navItems.map((item, index) => (
                  <Nav.Link key={index} href={item.href} className={item.className}>
                    {item.label}
                  </Nav.Link>
                ))}
      
            </div>
            <div className="dropdown_icon_container">
            <RxDropdownMenu className="dropdown_icon" onClick={() => setOpenNav(!openNav)} />
          </div>
          </div>
        </Container>
        {openNav && (
        <div className="mobile_nav">
          {navItems.map((item, index) => (
            <Nav.Link
              key={index}
              className={item.className}
              onClick={() => handleNavClick(item.href)}
            >
              {item.label}
            </Nav.Link>
          ))}
        </div>
      )}
      </Navbar>
    

      <Outlet />

      <footer className="bg-gray-900 text-white">
        <div className="footer_sect">
          <ItemsContainer />
          <hr />
          <div className="footer_text">
            <span>©{today} Appy. All rights reserved.</span>
            <span>Terms · Privacy Policy</span>
            <SocialIcons Icons={Icons} />
          </div>
        </div>
      </footer>
    </>
  );
};

export default DesignLayout;
